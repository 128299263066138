import axios from "axios";

// Create an Axios instance
const protocol = window.location.protocol || "https:";
console.log("protocol", protocol, process.env, process.env.VUE_APP_BASE_URL);
const service = axios.create({
  // baseURL: protocol + "//" + process.env.VUE_APP_BASE_URL,
  baseUrl: window.location.origin,
  timeout: 60000, // Request timeout period
});

// Request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// The response of interceptors
service.interceptors.response.use(
  (response) => {
    // console.log('response：', response.status, response)
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      return Promise.reject(response.data);
    }
  },
  (error) => {
    console.log("error：", error.response);
    if (!window.navigator.onLine) {
      console.log("The network is abnormal. Check the network connection");
    } else {
      if (error.response?.data?.message) {
        console.log(error.response.data.message);
      }
    }
    return Promise.reject(
      error?.response?.data ?? {
        message: "The network is abnormal. Check the network connection",
      }
    );
  }
);

export default service;
