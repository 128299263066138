import jwt_decode from "jwt-decode";
import vueCookies from "vue-cookies";
import { getToken, removeToken } from "@/utils/auth.js";
import { UserStatus } from "@/common/globalConstant";
import { get_user_info } from "@/api/user";
export default {
  namespaced: true,
  state: {
    walletAddress: "",
    token: getToken(),
    userIdentityStatus: -1,
    licence: -1,
    userId: "",
    userAvatar: "",
    invitation_code: "",
    roles: [],
    errorPublicHeadImg: 'this.src="' + require("@/assets/image/head.png") + '"',
    totalAmount: 0,
    amount: 0,
    withdrawAmount: 0,
    licenceMaxAmount: 0,
    pledgeAmount: 10000,
    title: "",
    canUpdateGoods: false,
    isLicenceMax: false,
    userNickName: "",
    annualVip: {
      is_annual_vip: false,
    },
    monthlyFee: {},
    title_current_exp: 0,
    title_need_exp: 0,
    currentNavBarTab: 0,
    specialWalletAddressArr: [
      "0xFC813F0daC9DC5F1CB4c2A85361f12b854E08348",
      "0x1bF0445052642534228AecEBc8A3D3125B2c5D35",
      "0xb2d90cd8733f37271b62caddc194c0cd7ecaf13c",
    ],
    userLevel: 0,
    userLevelRateConfig: {
      7: 90,
      6: 90,
      5: 80,
      4: 75,
      3: 70,
      2: 70,
      1: 70,
      0: 60,
    },
    userInfo: {},
  },
  mutations: {
    setUserInfo: (state, data) => {
      state.userInfo = Object.assign({}, data);
    },
    userLevel: (state, data) => {
      state.userLevel = data;
    },
    setCurrentNavBarTab: (state, data) => {
      state.currentNavBarTab = data;
    },
    setTitleCurrentExp: (state, data) => {
      state.title_current_exp = data;
    },
    setTitleNeedExp: (state, data) => {
      state.title_need_exp = data;
    },
    annualVip: (state, data) => {
      state.annualVip = Object.assign({}, data);
    },
    isAnnualMember: (state, data) => {
      state.isAnnualMember = data;
    },
    monthlyFee: (state, data) => {
      state.monthlyFee = Object.assign({}, data);
    },
    walletAddress(state, data) {
      state.walletAddress = data;
      // if (data.length > 1) {
      //   window.sessionStorage.setItem("walletAddress", data);
      // }
    },
    setUserId(state, data) {
      state.userId = data;
    },
    setToken(state, data) {
      state.token = data;
    },
    setTotalAmount(state, data) {
      state.totalAmount = data;
    },
    setAmount(state, data) {
      state.amount = data;
    },
    setWithdrawAmount(state, data) {
      state.withdrawAmount = data;
    },
    setLicenceMaxAmount(state, data) {
      state.licenceMaxAmount = data;
    },
    setRoles(state, data) {
      state.roles = JSON.parse(JSON.stringify(data));
    },
    setUserAvatar(state, data) {
      state.userAvatar = data;
    },
    setInvitationCode(state, data) {
      state.invitation_code = data;
    },
    setIsLicenceMax(state, data) {
      state.isLicenceMax = data;
    },
    setTitle(state, data) {
      state.title = data;
    },
    setCanUpdate(state, data) {
      state.canUpdateGoods = data;
    },
    setUserNickName(state, data) {
      state.userNickName = data;
    },
    removeToken(state) {
      state.token = "";
      state.walletAddress = "";
      state.userIdentityStatus = -1;
      state.licence = -1;
      state.invitationCode = "";
      state.isAnnualMember = false;
      state.monthlyFee = {};
      state.annualVip = {
        is_annual_vip: false,
      };
      state.roles = [];
      vueCookies.remove("token");
      // window.sessionStorage.removeItem("walletAddress");
      removeToken();
    },
    userIdentityStatus(state, data) {
      state.userIdentityStatus = data;
    },
    licence(state, data) {
      state.licence = data;
    },
  },
  actions: {
    checkPermissions({ commit, state }) {
      return new Promise((resolve, reject) => {
        let token = getToken();
        if (token) {
          let decoded = jwt_decode(token);
          let now = new Date().getTime() / 1000;
          if (decoded.exp < now) {
            commit("removeToken");
            reject();
          } else {
            commit("setToken", token);
            let userStatus = Number(decoded.status);
            commit("setRoles", [userStatus]);
            if (userStatus === UserStatus.BannedUsers) {
              reject();
            } else if (
              userStatus === UserStatus.NewUsers &&
              decoded.licence === 0
            ) {
              resolve("/goods");
            } else if (userStatus === UserStatus.TemporaryUsers) {
              resolve("/");
            } else {
              resolve("/");
            }
          }
        } else {
          reject();
        }
      });
    },
    decodeTokenData({ commit }, data) {
      return new Promise((resolve, reject) => {
        let token = jwt_decode(data);
        let userStatus = Number(token.status);
        if (userStatus === UserStatus.BannedUsers) {
          commit("removeToken");
          resolve("/login");
        }
        commit("setUserId", token.user_id);
        commit("setInvitationCode", token.invitation_code);
        commit("walletAddress", token.address);
        commit("userIdentityStatus", token.status);
        commit("licence", token.licence);
        commit("setRoles", [token.status]);
        if (userStatus === UserStatus.NewUsers && token.licence === 0) {
          resolve("/");
        }
        if (userStatus === UserStatus.TemporaryUsers) {
          resolve("/personal");
        }
        resolve("/");
      });
    },
    getUserInfo({ commit, rootState }, data = {}) {
      return new Promise((resolve, reject) => {
        data.lang =
          rootState.system.language === "jp" ? "ja" : rootState.system.language;
        get_user_info(data)
          .then((response) => {
            const { data } = response;
            if (!data) {
              reject("Verification failed, please Login again.");
            }
            const {
              user_id,
              avatar,
              invitation_code,
              address,
              status,
              licence,
              total_amount,
              amount,
              withdraw_amount,
              licence_max_amount,
              title,
              can_update,
              is_licence_max,
              nickname,
              title_current_exp,
              title_need_exp,
            } = data;
            commit("setTitleCurrentExp", title_current_exp);
            commit("setTitleNeedExp", title_need_exp);
            commit("setUserAvatar", avatar);
            commit("setUserId", user_id);
            commit("walletAddress", address);
            commit("setInvitationCode", invitation_code);
            commit("setIsLicenceMax", is_licence_max);
            commit("userIdentityStatus", status);
            commit("setRoles", [status]);
            commit("setTotalAmount", total_amount);
            commit("setAmount", amount);
            commit("setLicenceMaxAmount", licence_max_amount);
            commit("setWithdrawAmount", withdraw_amount);
            commit("licence", licence);
            commit("setTitle", title);
            commit("setCanUpdate", can_update);
            commit("setUserNickName", nickname);
            if (data?.annual_vip) {
              commit("annualVip", data.annual_vip);
            }
            if (data?.monthly_fee) {
              commit("monthlyFee", data.monthly_fee);
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("removeToken");
        resolve();
      });
    },
  },
  getters: {
    walletAddress: (state) => state.walletAddress,
    invitation_code: (state) => state.invitation_code,
    token: (state) => state.token,
    errorPublicHeadImg: (state) => state.errorPublicHeadImg,
  },
};
