import router from "@/router/index";
router.beforeEach(async (to, from, next) => {
  next();
  // if (getToken()) {
  //   let role = store.getters.roles;
  //   if (!role || role.length === 0) {
  //     store
  //       .dispatch("user/checkPermissions")
  //       .then((res) => {
  //         next(res);
  //       })
  //       .catch((err) => {
  //         next("/login");
  //       });
  //   } else {
  //     console.log("has role");
  //     next();
  //   }
  // } else {
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     next();
  //   } else {
  //     next("/login");
  //   }
  // }
});

// router.afterEach((to, from, next) => {
//   window.gtag("config", "G-7PEB494JJ9", {
//     page_title: to.name,
//     page_path: to.path,
//     page_location: window.location.href,
//   });
// });
