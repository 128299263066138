export default {
  namespaced: true,
  state: {
    isShowStakeInput: false,
  },
  mutations: {
    isShowStakeInput: (state, data) => {
      state.isShowStakeInput = data;
    },
  },
  actions: {},
};
