import request from "./request";

export function get_nonce(data) {
  return request({
    url: "/api/v1/get_nonce",
    method: "get",
    params: data,
  });
}

export function refresh_token(data) {
  return request({
    url: "/api/v1/refresh_token",
    method: "post",
    data,
  });
}

export function get_user_info(data) {
  return request({
    url: "/api/v1/get_user_info",
    method: "get",
    params: data,
  });
}

export function login_from_sign(data) {
  return request({
    url: "/api/v1/login",
    method: "post",
    data,
  });
}

export function get_invited_users(data) {
  return request({
    url: "/api/v1/get_invited_users",
    method: "get",
    params: data,
  });
}

export function change_nickname(data) {
  return request({
    url: "/api/v1/nickname",
    method: "post",
    data,
  });
}

export function get_spender_address(data) {
  return request({
    url: "/api/v1/get_spender_address",
    method: "get",
    params: data,
  });
}

export function get_reward_history(data) {
  return request({
    url: "/api/v1/stake/rewards",
    method: "get",
    params: data,
  });
}

export function bind(data) {
  return request({
    url: "/api/v1/bind",
    method: "post",
    data,
  });
}

export function update_nickname(data) {
  return request({
    url: "/api/v1/update_nickname",
    method: "post",
    data,
  });
}
