import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./i18n/index";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import "./assets/css/common.css";
import "./assets/css/main.scss";
import "./permission.js";
Vue.config.productionTip = false;
Vue.config.$cookies = VueCookies;
import VConsole from "vconsole";
import gaPulgin from "./plugins/analyst";
import VueClipboard from "vue-clipboard2";
import { browser } from "@/utils/utils";
import { getUrlParamsByName } from "@/utils";
window.vConsole = null;
Vue.use(VueClipboard);
Vue.use(gaPulgin);
let isOpenConsole = getUrlParamsByName("vc");
console.log("isOpenConsole", isOpenConsole);
if (
  (process.env.NODE_ENV === "development" && browser.versions.mobile) ||
  isOpenConsole
) {
  window.vConsole = new VConsole();
  Vue.use(window.vConsole);
  console.log("vConsole", window.vConsole);
  // console.log = function () {}
  // console.error = function(){}
  // console.dir = function(){}
}

Vue.use(VueCookies, { expire: "1d" });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
