import { ethers } from "ethers";

export const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum);
};

export function getProvider() {
  if (isMetaMaskInstalled()) {
    console.log("connect window.ethereum");
    window.provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    if (window.web3) {
      console.log("connect web3.currentProvider");
      window.provider = new ethers.providers.Web3Provider(
        window.web3.currentProvider
      );
    } else {
      try {
        console.log("connect JsonRpcProvider");
        window.provider = new ethers.providers.JsonRpcProvider(
          window.switchNetworkParam.rpcUrls[0]
        );
      } catch (e) {
        console.error("provider err:", e);
      }
    }
  }
}

export async function getContract(address, abi) {
  if (!window.provider) {
    await getProvider();
  }
  let signer = await window.provider.getSigner();
  const contract = new ethers.Contract(address, abi, signer);
  return contract;
}

export function getUrlParamsByName(name) {
  // \b 边界
  // ?<= 向后匹配
  // 字符串转成正则表达式，其中的'\b'类型的特殊字符要多加一个'\'
  let reg = new RegExp(`(?<=\\b${name}=)[^&]*`),
    str = window.location.search || "",
    target = str.match(reg);
  if (target) {
    return target[0];
  }

  return;
}
